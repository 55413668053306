import * as React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Query } from '@generated/graphql'

import Layout from '@components/Layout'
import { Grid, Box, IFrame, H3, H4 } from '@components/UI'

const Title = styled(H3).attrs({
  pb: 1
})``

const Description = styled(H4).attrs({
  pb: [2, 3],
  color: 'dark'
})``

const VideoBox = styled(Box).attrs({
  pb: [2, 3]
})``

const ASPECT_RATIO = 121 / 68

const VideosGrid = styled(Grid).attrs({
  spacing: [2, 3, 3],
  numCols: [1, 2]
})``

const Videos = ({ data }: { data: Query }) => {
  const sources = _.get(
    data,
    'allMarkdownRemark.nodes[0].frontmatter.youtubeSources',
    []
  )
  return (
    <Layout title="Videos">
      <VideosGrid
        elements={sources}
        render={({
          title,
          description,
          url
        }: {
          title: string
          description: string
          url: string
        }) => {
          return (
            <VideoBox>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <IFrame
                url={url}
                title={title}
                getHeight={(width: number) => width / ASPECT_RATIO}
              />
            </VideoBox>
          )
        }}
      />
    </Layout>
  )
}

/* STODO pass iframe additional props to IFrame?
<iframe
  width="100%"
  title={title}
  src={url}
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  style={{ border: 'none' }}
/>
*/

export default Videos

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content.videos.md/" } }
    ) {
      nodes {
        frontmatter {
          youtubeSources {
            url
            title
            description
          }
        }
      }
    }
  }
`
